/**
 * Checks if a valid product is existing in the store
 * for the current flow (determined by the route).
 * If not, redirect to Step 1 of that flow
 */
export default defineNuxtRouteMiddleware((to) => {
    const shopStore = useShopStore();
    const flowStore = useFlowStore();
    const pathParts = to.path.split('/');
    const productKeyInPath = pathParts[pathParts.length - 2] ?? '';

    if (flowStore.isUpsellFlow) {
        // Get the product key from the route and check if a corresponding upsell product exists
        if (!Object.keys(shopStore.productsUpsell).includes(productKeyInPath)) {
            return navigateTo(getFirstFlowRoute(flowStore.productConfigUpsell?.steps));
        }
    }
    else {
        // Get the product key from the route and check if a corresponding main product exists
        if (!Object.keys(shopStore.productsMain).includes(productKeyInPath)) {
            return navigateTo(getFirstFlowRoute(flowStore.productConfigMain?.steps));
        }
    }
});
